<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <template #activator="{ on, attrs }">
        <v-btn small v-bind="attrs" v-on="on">
          <v-icon small>
            {{ ICON.ADD_PLUS }}
          </v-icon>
          {{ COMMON.ADD_LANGUAGE }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline primary modaltext--text">
          {{ COMMON.ADD_LANGUAGE }}
        </v-card-title>

        <v-form ref="form" @submit.prevent="onSubmit">
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="language.name"
                  outlined
                  dense
                  background-color="white"
                  label="Name"
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="dialog = false">
              {{ COMMON.CANCEL_BTN }}
            </v-btn>
            <v-btn color="primary" type="submit" :loading="isBusy">
              Add
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" absolute right top>
      {{ snackBarText }}
      <template #action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import common from "@/commons/common";

  export default {
    name: "AddLanguage",

    data() {
      return {
        dialog: false,
        COMMON: common,
        ICON: common.ICON,

        isBusy: false,

        language: {
          name: "",
        },

        rules: {
          required: (value) => !!value || "Required.",
        },

        snackbar: false,
        snackBarText: "This is default text!",
        timeout: 2000,
      };
    },

    methods: {
      onSubmit() {
        if (this.$refs.form.validate()) {
          this.createLanguage();
        }
      },

      async createLanguage() {
        this.isBusy = true;
        await this.$store
          .dispatch("language/createLanguage", this.language)
          .then((res) => {
            if (res.status == 200) {
              this.enableSnackbar("Language Created!");
            }
          })
          .finally(() => {
            this.language.name = "";
            this.isBusy = false;
            this.dialog = false;
          });
      },

      enableSnackbar(text) {
        this.snackbar = true;
        this.snackBarText = text;
      },
    },
  };
</script>

<style></style>
