<template>
  <div>
    <v-container>
      <v-row
        justify="space-between"
        align="center"
        class="mx-3"
      >
        <div class="headline">
          {{ COMMON.CATEGORY_LABEL }}
        </div>
        <div class="d-flex">
          <add-category />
        </div>
      </v-row>

      <v-data-table
        :loading="isBusy"
        :items="!isBusy ? categoryList : []"
        :headers="headers"
        :items-per-page="10"
        hide-default-footer
        class="elevation-1 my-4"
        loading-text="Loading... Please wait"
        :page.sync="page"
        @page-count="pageCount = $event"
      >
        <template #[`item.subcategories`]="{ item }">
          <v-chip
            v-for="sub in item.subCategories"
            :key="sub.name"
            small
            close
            class="ma-1"
            @click:close="selectedCategory = sub"
          >
            {{ sub.name }}
          </v-chip>
        </template>
        <template #[`item.language`]="{ item }">
          <v-chip
            small
            :color="
              item.language.name == 'English'
                ? 'chip_odd'
                : item.language.name == 'Hindi'
                  ? 'chip_even'
                  : 'GREEN'
            "
          >
            {{ item.language.name }}
          </v-chip>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            icon
            @click="selectedCategory = item"
          >
            <v-icon dark>
              {{ ICON.DELETE_TRASH }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <v-pagination
        v-model="page"
        :length="pageCount"
      />

      <v-dialog
        v-model="showDeleteDialog"
        persistent
        max-width="320"
      >
        <v-card v-if="selectedCategory">
          <v-card-title class="title">
            Do you want to delete {{ selectedCategory.name }} ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="isBusy"
              text
              @click="selectedCategory = null"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              :loading="isBusy"
              @click="deleteCategory(selectedCategory.name)"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        absolute
        right
        top
      >
        {{ snackBarText }}
        <template #action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import common from '@/commons/common';
import AddCategory from '../components/category/AddCategory.vue';

export default {
  name: 'Categories',

  components: { AddCategory },

  data() {
    return {
      COMMON: common,
      ICON: common.ICON,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Subcategories', value: 'subcategories', sortable: false },
        { text: 'Language', value: 'language', sortable: false },
        { text: 'Actions', value: 'actions', align: 'end', sortable: false },
      ],
      isBusy: false,

      page: 1,
      pageCount: 0,

      selectedCategory: null,

      snackbar: false,
      snackBarText: 'This is default text!',
      timeout: 2000,
    };
  },

  computed: {
    categoryList() {
      return this.$store.state.category._categories;
    },

    showDeleteDialog() {
      return !!this.selectedCategory;
    },
  },

  mounted() {
    this.$store.dispatch('category/retrieveLanguages');
  },

  async created() {
    this.isBusy = true;
    await this.$store.dispatch('category/retrieveCategories');
    this.isBusy = false;
  },

  methods: {
    enableSnackbar(text) {
      this.snackbar = true;
      this.snackBarText = text;
    },

    async deleteCategory({ categoryId }) {
      this.isBusy = true;
      await this.$store
        .dispatch('category/deleteCategory', this.selectedCategory.id)
        .then((res) => {
          if (res !== undefined && res.status === 200) {
            this.selectedCategory = null;
            this.enableSnackbar('Category Deleted!');
          } else {
            this.selectedCategory = null;
            this.enableSnackbar('Category  might be associated with capsule!');
          }
        })
        .catch(function(err) {
          if(err.response.status == 422) {
            this.enableSnackbar('Category  might be associated with capsule!');
          }
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>

<style></style>
