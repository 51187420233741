<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <template #activator="{ on, attrs }">
        <v-btn
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small>
            {{ ICON.ADD_PLUS }}
          </v-icon>
          {{ COMMON.ADD_CATEGORY }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline primary modaltext--text">
          {{ COMMON.ADD_CATEGORY }}
        </v-card-title>

        <v-form
          ref="form"
          @submit.prevent="onSubmit"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <!-- <v-select
                  v-model="category.language"
                  :items="languageList"
                  item-text="name"
                  return-object
                  outlined
                  dense
                  background-color="white"
                  label="Select Language"
                  hide-details="auto"
                  :rules="[rules.required]"
                /> -->
                <v-select
                  v-model="category.language"
                  :items="languages"
                  item-text="name"
                  outlined
                  dense
                  background-color="white"
                  label="Language"
                  hide-details="auto"
                  :rules="[rules.required]"
                  @change="selectLanguage"
                />
              </v-col>
              <v-col :cols="12">
                <v-text-field
                  v-model="category.name"
                  outlined
                  dense
                  background-color="white"
                  label="Category"
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col :cols="12">
                <v-select
                  :items="categoryList"
                  item-text="name"
                  item-value="name"
                  outlined
                  dense
                  background-color="white"
                  label="Parent Category (optional)"
                  hide-details="auto"
                  @change="selectParentCategory"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              {{ COMMON.CANCEL_BTN }}
            </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :loading="isBusy"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      absolute
      right
      top
    >
      {{ snackBarText }}
      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import common from '@/commons/common';
import { mapState } from 'vuex';

export default {
  name: 'AddCategory',

  data() {
    return {
      dialog: false,
      COMMON: common,
      ICON: common.ICON,

      isBusy: false,

      category: {
        language: null,
        name: '',
        parent: null,
      },

      rules: {
        required: (value) => !!value || 'Required.',
      },

      snackbar: false,
      snackBarText: 'This is default text!',
      timeout: 2000,
    };
  },

  computed: {
    ...mapState({
      languages: (state) => state.capsules._languages,
    }),
    // languageList() {
    //   return this.$store.state.category._languages;
    // },

    categoryList() {
      return this.$store.state.category._langCategories;
    },
  },

  created() {
    this.$store.dispatch('category/retrieveCategoriesByLang', this.category.language);
  },

  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.createCategory();
      }
    },

    async createCategory() {
      this.isBusy = true;
      this.$store
        .dispatch('category/createCategory', this.category)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.enableSnackbar('Category Created!');
          }
        })
        .finally(() => {
          this.category = {
            name: '',
            parent: null,
          };
          this.isBusy = false;
          this.dialog = false;
        });
    },

    enableSnackbar(text) {
      this.snackbar = true;
      this.snackBarText = text;
    },

    selectParentCategory(event) {
      let parentcat = this.categoryList.filter(object => object.name === event)[0]
      this.category = {
        ...this.category,
        parent: parentcat,
      };
    },

    selectLanguage(event) {
      this.$store.dispatch('category/retrieveCategoriesByLang', event);
    },
  },
};
</script>

<style></style>
