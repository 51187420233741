<template>
  <div>
    <v-container>
      <v-row
        justify="space-between"
        align="center"
        class="mx-3"
      >
        <div class="headline">
          {{ COMMON.CAPSULES_TITLE }}
        </div>
        <div class="d-flex">
          <AddCapsule />

          <v-btn
            dense
            class="ml-4"
            :disabled="selectedData.length == 0"
            @click="broadcast()"
          >
            {{ COMMON.BROADCAST_LABEL_BTN }}
          </v-btn>

          <downloadexcel
            class="btn"
            :fetch="fetchData"
            :fields="json_fields"
            type="csv"
          >
            <v-btn 
              small
              class="ml-4" 
            >
              Excel Report
            </v-btn>
          </downloadexcel>

          <!-- <v-btn 
            class="dropdown-item" 
            @click="downloadReport()"
          >
            Excel Report
          </v-btn> -->
        </div>
      </v-row>
      <v-row
        justify="end"
        align="end"
        class="mx-3 my-3"
      >
        <div class="d-flex">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dateRange"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                dense
                label="Select Dates"                
                class="ml-4" 
                prepend-inner-icon="mdi-calendar"
                outlined
                readonly
                v-bind="attrs"
                :rules="[rules.required]"
                hide-details="auto"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="dateRange"
              no-title
              scrollable
              range
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(dateRange)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

          <downloadexcel
            class="btn"
            :fetch="fetchData"
            :fields="json_fields"
            type="csv"
          >
            <v-btn 
              dense
              class="ml-4" 
            >
              Excel Report
            </v-btn>
          </downloadexcel>

          <!-- <v-btn 
            class="dropdown-item" 
            @click="downloadReport()"
          >
            Excel Report
          </v-btn> -->
        </div>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            v-model="selectedData"
            :headers="headers"
            :items="!isBusy ? capsules : []"
            :items-per-page="10"
            hide-default-footer
            class="elevation-1"
            :loading="isBusy"
            :single-select="true"
            show-select
            loading-text="Loading... Please wait"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <template #[`item.thumb`]="{ item }">
              <v-avatar
                tile
                size="32"
              >
                <img
                  :src="
                    item.thumbnailUrl
                      ? item.thumbnailUrl
                      : require('@/assets/logo.png')
                  "
                  alt="Thumbnail"
                >
              </v-avatar>
            </template>

            <template #[`item.category`]="{ item }">
              <v-chip
                small
                class="ma-1"
              >
                {{ item.category.name }}
              </v-chip>
            </template>

            <template #[`item.language`]="{ item }">
              <v-chip
                small
                :color="
                  item.language.name == 'English'
                    ? 'chip_odd'
                    : item.language.name == 'Hindi'
                      ? 'chip_even'
                      : 'GREEN'
                "
              >
                {{ item.language.name }}
              </v-chip>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-row>
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      v-on="on"
                      @click="previewVideo(item)"
                    >
                      <v-icon dark>
                        {{ ICON.VIDEO_PREVIEW }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span> {{ COMMON.PREVIEW_CAPSULE }} </span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      v-on="on"
                      @click="editCapsule(item)"
                    >
                      <v-icon dark>
                        {{ ICON.EDIT_SQUARE }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span> {{ COMMON.EDIT_CAPSULE }} </span>
                </v-tooltip>

                <DeleteCapsule
                  v-bind="item"
                  @on-delete="deleteCapsule"
                />
              </v-row>
            </template>
          </v-data-table>

          <v-pagination
            v-model="page"
            :length="pageCount"
          />
        </v-col>
      </v-row>

      <v-dialog
        v-model="previewDialog"
        hide-overlay
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-card
          color="#FFFFFF"
          dark
        >
          <v-toolbar
            dark
            class="navbar--header"
          >
            <div class="d-flex mr-4 align-center">
              <v-img
                alt="Newsaudible"
                class="shrink mt-1 hidden-sm-and-down"
                contain
                min-width="100"
                :src="require('@/assets/logo.png')"
                width="100"
              />
            </div>
            <v-spacer />

            <v-toolbar-title>
              {{ COMMON.PREVIEW_CAPSULE }}
            </v-toolbar-title>

            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="closeDialog"
              >
                <v-icon>{{ ICON.CLOSE }}</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text v-if="previewVideoItem != null">
            <div
              class="my-6 secondary--text"
              style="text-align: center"
            >
              <h3>Title: {{ previewVideoItem.title }}</h3>
              <h4>Language: {{ previewVideoItem.language.name }}</h4>
              <h4>Category: {{ previewVideoItem.category.name }}</h4>
            </div>
            <div class="player-container mx-auto">
              <video-player
                v-if="previewDialog"
                :options="videoOptions"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        absolute
        right
        top
      >
        {{ snackBarText }}
        <template #action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>

    <EditCapsule v-model="edit" />
  </div>
</template>

<script>
import common from '@/commons/common';
import AddCapsule from '@/components/capsules/AddCapsule';
import DeleteCapsule from '@/components/capsules/DeleteCapsule';
import EditCapsule from '@/components/capsules/EditCapsule';
import VideoPlayer from '@/components/VideoPlayer';
import { saveAs } from 'file-saver';
import downloadexcel from 'vue-json-excel';

import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    AddCapsule,
    DeleteCapsule,
    EditCapsule,
    VideoPlayer,
    downloadexcel,
  },

  data() {
    return {
      COMMON: common,
      broadcastDone: false,

      ICON: common.ICON,
      isBusy: false,
      menu: false,
      dateRange: [],
      selectedData: [],

      snackbar: false,
      snackBarText: 'This is default text!',
      timeout: 2000,

      capsuleVideo: {
        startDate: '',
        endDate: '',
      },

      videoOptions: {
        autoplay: true,
        controls: true,
        height: 500,
        width: 600,
        liveui: true,
        sources: [
          {
            src: '',
            type: 'video/mp4',
          },
        ],
      },

      previewDialog: false,
      previewVideoItem: null,

      headers: [
        {
          text: 'Thumbnail',
          align: 'start',
          value: 'thumb',
        },
        {
          text: 'Title',
          align: 'start',
          value: 'title',
        },
        { text: 'Feature', value: 'category', sortable: true },
        { text: 'Language', value: 'language', sortable: true },
        { text: 'Filename', value: 'filename', sortable: true },
        { text: 'Uploaded Date', value: 'uploadedDate', sortable: true },
        { text: 'Play Count', value: 'playCount', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      page: 1,
      pageCount: 0,

      json_fields: {
        'Capsule Title': 'title',
        'Play Count': 'playCount',
      },

      rules: {
        required: (value) => !!value || 'Required.',
      },

      edit: false,
    };
  },

  computed: {
    ...mapState({ capsules: (state) => state.capsules._capsules }),

    dateRangeText() {
      return this.dateRange.join(' ~ ');
    },
  },

  watch: {
    dateRange: {
      handler(nv) {
        this.capsuleVideo.startDate = `${nv[0]} 00:00:00`;
        this.capsuleVideo.endDate = `${nv[1]} 00:00:00`;
      },
    },
  },

  mounted() {
    this.loadCapsules();
    this.$store.dispatch('capsules/retrieveCategories');
    this.$store.dispatch('capsules/retrieveLanguages');
  },

  methods: {
    ...mapMutations({ setCapsuleDetail: 'capsules/SET_CAPSULE_DETAIL' }),

    enableSnackbar(text) {
      this.snackbar = true;
      this.snackBarText = text;
    },    

    async downloadReport() {
      try {
        this.isBusy = true;
        const { data } = await this.$axios.get('/news-videos');
        saveAs(new Blob([data]), 'AdVideoReport.xlsx');
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },

    async fetchData() {
      const response = await this.$axios.get('/news-videos');
      console.log(response);
      return response.data;
    },

    startDownload() {
      alert('show loading');
    },

    finishDownload() {
      alert('hide loading');
    },

    loadCapsules() {
      this.isBusy = true;
      this.$store.dispatch('capsules/retrieveCapsules').then((res) => {
        if (res.status == 200) this.isBusy = false;
      });
    },

    broadcast() {
      let videoData = this.selectedData[0];
      this.$axios
        .post(`news-videos/${videoData.id}/broadcast`, { ...videoData })
        .then((res) => {
          if (res.status === 200) {
            // this.broadcastResponseStatus = 'OK';
            this.enableSnackbar('OK!');
            this.broadcastDone = true;
          } else if (res.status === 201) {
            // this.broadcastResponseStatus = 'Created';
            this.enableSnackbar('Created!');
            this.broadcastDone = true;
          } else if (res.status === 401) {
            // this.broadcastResponseStatus = 'Unauthorized';
            this.enableSnackbar('Unauthorized!');
            this.broadcastDone = true;
          } else if (res.status === 403) {
            // this.broadcastResponseStatus = 'Forbidden';
            this.enableSnackbar('Forbidden!');
            this.broadcastDone = true;
          } else if (res.status === 404) {
            // this.broadcastResponseStatus = 'Not Found';
            this.enableSnackbar('Not Found!');
            this.broadcastDone = true;
          }
        });
    },

    previewVideo(item) {
      if (item != null) {
        this.previewVideoItem = item;
        this.videoOptions.sources[0].src = `${process.env.VUE_APP_BASE_URL}/news-videos/stream/${item.id}`;
        this.previewDialog = true;
      }
    },

    closeDialog() {
      this.previewDialog = false;
    },

    async deleteCapsule(id) {
      let res = await this.$store.dispatch('capsules/deleteCapsule', id);
      if (res.status == 200) this.enableSnackbar('Capsule Deleted!');
    },

    editCapsule(capsule) {
      this.setCapsuleDetail(capsule);
      this.edit = true;
    },
  },
};
</script>

<style scoped>
  .player-container {
    width: 600px;
    height: 500px;
  }
</style>
