<template>
  <div>
    <v-container>
      <v-row
        justify="space-between"
        align="center"
        class="mx-3"
      >
        <div class="headline">
          {{ COMMON.LANGUAGE_LABLE }}
        </div>
        <div class="d-flex">
          <add-language />
        </div>
      </v-row>

      <v-data-table
        :loading="isBusy"
        :items="!isBusy ? languageList : []"
        :headers="headers"
        :items-per-page="10"
        hide-default-footer
        class="elevation-1 my-4"
        loading-text="Loading... Please wait"
        :page.sync="page"
        @page-count="pageCount = $event"
      >
        <template #[`item.actions`]="{ item }">
          <v-btn
            icon
            @click="selectedLanguage = item"
          >
            <v-icon dark>
              {{ ICON.DELETE_TRASH }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <v-pagination
        v-model="page"
        :length="pageCount"
      />

      <v-dialog
        v-model="showDeleteDialog"
        persistent
        max-width="320"
      >
        <v-card v-if="selectedLanguage">
          <v-card-title class="title">
            Do you want to delete {{ selectedLanguage.name }} ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="isBusy"
              text
              @click="selectedLanguage = null"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              :loading="isBusy"
              @click="deleteLanguage(selectedLanguage)"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        absolute
        right
        top
      >
        {{ snackBarText }}
        <template #action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import common from '@/commons/common';
import AddLanguage from '../components/language/AddLanguage.vue';

export default {
  name: 'Languages',

  components: { AddLanguage },

  data() {
    return {
      COMMON: common,
      ICON: common.ICON,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Actions', value: 'actions', align: 'end', sortable: false },
      ],
      isBusy: false,

      page: 1,
      pageCount: 0,

      selectedLanguage: null,

      snackbar: false,
      snackBarText: 'This is default text!',
      timeout: 2000,
    };
  },

  computed: {
    languageList() {
      return this.$store.state.language._languages;
    },
    showDeleteDialog() {
      return !!this.selectedLanguage;
    },
  },

  async created() {
    this.isBusy = true;
    await this.$store.dispatch('language/retrieveLanguages');
    this.isBusy = false;
  },

  methods: {
    enableSnackbar(text) {
      this.snackbar = true;
      this.snackBarText = text;
    },

    async deleteLanguage({ name }) {
      this.isBusy = true;
      await this.$store
        .dispatch('language/deleteLanguage', name)
        .then((res) => {
          if (res.status === 200) {
            this.selectedLanguage = null;
            this.enableSnackbar('Language Deleted!');
          }
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>

<style></style>
