<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <template #activator="{ on, attrs }">
        <v-btn
          dense
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small>
            {{ ICON.ADD_PLUS }}
          </v-icon>
          {{ COMMON.ADD_CAPSULE }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline primary modaltext--text">
          {{ COMMON.ADD_CAPSULE }}
        </v-card-title>

        <v-form
          ref="capsuleForm"
          @submit.prevent="onSubmit"
        >
          <v-card-text>
            <v-row class="d-flex justify-center align-cneter">
              <v-col>
                <v-file-input
                  v-model="video"
                  accept="video/mp4"
                  prepend-icon=""
                  :prepend-inner-icon="ICON.VIDEO"
                  outlined
                  dense
                  background-color="white"
                  label="Select video"
                  hide-details="auto"
                  @change="uploadVideo"
                />
              </v-col>
            </v-row>

            <v-dialog
              v-model="uploading"
              hide-overlay
              persistent
              width="300"
            >
              <v-card
                color="primary"
                dark
              >
                <v-card-text>
                  Uploading, Please wait...
                  <v-progress-linear
                    color="light-green darken-4"
                    height="10"
                    :value="uploaded"
                    stripped
                  />
                </v-card-text>
              </v-card>
            </v-dialog>

            <template v-if="videoUploaded">
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="image"
                    accept="image/*"
                    prepend-icon=""
                    :prepend-inner-icon="ICON.IMAGE"
                    outlined
                    dense
                    background-color="white"
                    label="Select thumbnail"
                    hide-details="auto"
                    :rules="[rules.required]"
                    @change="uploadThumbnail"
                    @click:clear="capsule.thumbnailUrl = null"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="capsule.title"
                    outlined
                    dense
                    background-color="white"
                    label="Video's title"
                    hide-details="auto"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col cols="6">
                  <v-select
                    v-model="capsule.language"
                    :items="languages"
                    item-text="name"
                    outlined
                    dense
                    background-color="white"
                    label="Select Language"
                    hide-details="auto"
                    :rules="[rules.required]"
                    @change="selectLanguage"
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="capsule.category"
                    :items="categories"
                    item-text="name"
                    return-object
                    outlined
                    dense
                    background-color="white"
                    label="Select Category"
                    hide-details="auto"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  v-if="subCategories.length"
                  cols="6"
                >
                  <v-select
                    v-model="capsule.subCategory"
                    :items="subCategories"
                    item-text="name"
                    item-value="name"
                    return-object
                    outlined
                    dense
                    background-color="white"
                    label="Select Subcategory"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
            </template>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="cancel"
            >
              {{ COMMON.CANCEL_BTN }}
            </v-btn>
            <v-btn
              v-show="videoUploaded"
              color="primary"
              type="submit"
            >
              <span v-if="!isBusy"> Add </span>
              <v-progress-circular
                v-else
                indeterminate
              />
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      absolute
      right
      top
    >
      {{ snackBarText }}
      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import common from '@/commons/common';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      COMMON: common,
      ICON: common.ICON,
      isBusy: false,
      uploading: false,

      dialog: false,
      video: null,
      videoUploaded: false,
      image: null,

      snackbar: false,
      snackBarText: 'This is default text!',
      timeout: 2000,

      capsule: {
        title: '',
        url: null,
        thumbnailUrl: null,
        filename: null,
        category: {},
        language: {},
      },

      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },

  computed: {
    ...mapState({
      // categories: (state) => state.capsules._langCategories,
      languages: (state) => state.capsules._languages,
      uploaded: (state) => state.capsules._uploaded,
    }),

    categories() {
      return this.$store.state.capsules._langCategories;
    },

    subCategories() {
      return this.capsule.category?.subCategories ?? [];
    },
  },

  methods: {
    enableSnackbar(text) {
      this.snackbar = true;
      this.snackBarText = text;
    },

    uploadComplete() {
      this.videoUploaded = true;
      this.uploading = false;
    },

    // async uploadVideo(video) {
    //   if (video != null) {
    //     this.uploading = true;

    //     await this.$store
    //       .dispatch("capsules/uploadVideo", video)
    //       .then((res) => {
    //         if (res.status == 200) {
    //           this.capsule.url = res.data.url;
    //           this.capsule.filename = res.data.filename;
    //           if (this.capsule.url && this.capsule.filename)
    //             this.uploadComplete();
    //           this.enableSnackbar("Video Uploaded!");
    //         }
    //       });
    async uploadVideo(video) {
      if (!video) return;
      try {
        this.uploading = true;
        const { status, data } = await this.$store.dispatch(
          'capsules/uploadVideo',
          video
        );

        if (status === 200) {
          this.capsule.url = data.url;
          this.capsule.filename = data.filename;
          this.videoUploaded = true;
          this.enableSnackbar('Video Uploaded!');
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.uploading = false;
      }
    },

    async uploadThumbnail(image) {
      if (!image) return;
      try {
        this.uploading = true;
        const { status, data } = await this.$store.dispatch(
          'capsules/uploadThumbnail',
          image
        );
        if (status === 200) {
          this.capsule.thumbnailUrl = data.url;
          this.enableSnackbar('Thumbnail Uploaded!');
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.uploading = false;
      }
    },

    cancel() {
      this.dialog = false;
      this.videoUploaded = false;
      this.$refs.capsuleForm.reset();
    },

    // onSubmit() {
    //   if (this.$refs.form.validate()) {
    //   this.$refs.capsuleForm.reset();
    // },

    onSubmit() {
      if (this.$refs.capsuleForm.validate()) {
        this.addCapsule();
      }
    },

    async addCapsule() {
      this.isBusy = true;
      if (this.capsule.subCategory) {
        this.capsule = {
          ...this.capsule,
          category: this.capsule.subCategory,
        };
      }
      await this.$store
        .dispatch('capsules/createCapsule', this.capsule)
        .then((res) => {
          if (res.status == 201) {
            this.isBusy = false;
            this.cancel();
            this.enableSnackbar('Capsule Created!');
          }
        })
        .finally((this.isBusy = false));
    },

    selectLanguage(event) {
      this.$store.dispatch('capsules/retrieveCategoriesByLang', event);
    },
  },
};
</script>

<style>
  .v-list-item .v-list-item__title,
  .v-select__selections {
    line-height: 23px;
  }
</style>
