<template>
  <div>
    <v-dialog v-model="show" width="500" persistent>
      <v-card>
        <v-card-title class="headline primary modaltext--text">
          {{ COMMON.EDIT_CAPSULE }} {{ `'${capsule.title}'?` }}
        </v-card-title>

        <v-form ref="form" @submit.prevent="onSubmit">
          <v-card-text style="min-height: 200px">
            <v-row class="d-flex justify-center">
              <v-col>
                <v-text-field
                  v-model="updates.filename"
                  outlined
                  dense
                  background-color="white"
                  label="Video file"
                  readonly
                  hide-details="auto"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="updates.title"
                  outlined
                  dense
                  background-color="white"
                  label="Video's title"
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="updates.language"
                  :items="languages"
                  item-text="name"
                  return-object
                  outlined
                  dense
                  background-color="white"
                  label="Select Language"
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="updates.category"
                  :items="categories"
                  item-text="name"
                  return-object
                  outlined
                  dense
                  background-color="white"
                  label="Select Category"
                  hide-details="auto"
                  :rules="[rules.required]"
                  @change="updates.subCategory = null"
                />
              </v-col>
              <v-col v-if="subCategories.length" cols="12">
                <v-select
                  v-model="updates.subCategory"
                  :items="subCategories"
                  item-text="name"
                  return-object
                  outlined
                  dense
                  background-color="white"
                  label="Select Subcategory"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="!isBusy"
              color="primary"
              text
              @click.stop="show = false"
            >
              {{ COMMON.CANCEL_BTN }}
            </v-btn>

            <v-btn color="primary" type="submit">
              <span v-if="!isBusy"> Update </span>
              <v-progress-circular v-else indeterminate />
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" absolute right top>
      {{ snackBarText }}
      <template #action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import common from "@/commons/common";
  import { mapState } from "vuex";
  export default {
    props: {
      value: { type: Boolean },
    },

    data() {
      return {
        COMMON: common,
        ICON: common.ICON,
        isBusy: false,

        snackbar: false,
        snackBarText: "This is default text!",
        timeout: 2000,

        updates: {
          title: "",
          url: null,
          filename: null,
          category: {},
          language: {},
        },

        rules: {
          required: (value) => !!value || "Required.",
        },
      };
    },

    computed: {
      ...mapState({
        categories: (state) => state.capsules._categories,
        languages: (state) => state.capsules._languages,
        uploaded: (state) => state.capsules._uploaded,
        capsule: (state) => state.capsules._capsuleDetail,
      }),

      show: {
        get() {
          return this.value;
        },

        set(value) {
          this.$emit("input", value);
        },
      },

      subCategories() {
        return (
          this.categories.find((cat) => cat.name === this.updates.category.name)
            ?.subCategories ?? []
        );
      },
    },

    watch: {
      capsule(nv) {
        this.updates = {
          ...nv,
          category: nv.category.parent ? nv.category.parent : nv.category,
          subCategory: nv.category.parent ? nv.category : null,
        };
      },
    },

    methods: {
      onSubmit() {
        if (this.$refs.form.validate()) {
          this.editCapsule();
        }
      },

      enableSnackbar(text) {
        this.snackbar = true;
        this.snackBarText = text;
      },

      editCapsule() {
        this.isBusy = true;
        if (this.updates.subCategory) {
          this.updates = {
            ...this.updates,
            category: this.updates.subCategory,
          };
        }
        this.$store
          .dispatch("capsules/updateCapsule", this.updates)
          .then((res) => {
            if (res.status == 200) {
              this.isBusy = false;
              this.show = false;
              this.enableSnackbar("Capsule Updated!");
            }
          })
          .catch((error) => {
            this.isBusy = false;
            console.log(error);
          });
      },
    },
  };
</script>

<style></style>
